import './check_list_box.styles';

class CheckListBox {
  constructor (clb) {
    this.checkboxes = clb.find('.clb-checkbox');
    this.result = clb.find('.clb-value');

    this.checkboxes.click(() => this.updateValue());
  }

  updateValue () {
    this.result.val(this.checkboxes.filter(':checked').map((_, e) => $(e).data('id')).get().join());
  }
}

$(document).on('rails_admin.dom_ready', () => {
  $('fieldset.check_list_box').each((_, e) => new CheckListBox($(e)));
});
