export default class {
  constructor({ element, initialItems, onUpdate }) {
    this.onUpdate = onUpdate;

    this.grid = new AppendGrid({
      element,
      uiFramework: "bootstrap4",
      sectionClasses: {
        table: "table-float"
      },
      columns: [
        {
          name: "id",
          display: "ID",
          type: "hidden"
        },
        {
          name: "name",
          display: "Name",
          type: "custom",
          customBuilder: (parent, idPrefix, name, uniqueIndex) => {
            const controlId = idPrefix + "_" + name + "_" + uniqueIndex;
            const element = document.createElement("div");
            element.id = controlId;
            parent.appendChild(element);

            parent.style.setProperty('text-align', 'left', 'important');
          },
          customSetter: function(idPrefix, name, uniqueIndex, value) {
            const controlId = idPrefix + "_" + name + "_" + uniqueIndex;
            document.getElementById(controlId).innerText = value;
          },
          customGetter: (idPrefix, name, uniqueIndex) => {
            const controlId = idPrefix + "_" + name + "_" + uniqueIndex;
            return document.getElementById(controlId).innerText;
          }
        },
        {
          name: "show",
          display: "Show",
          type: 'checkbox',
          displayCss: {
            "width": "80px"
          },
          events: {
            change: () => this.updateResult()
          }
        },
        {
          name: "edit",
          display: "Edit",
          type: 'checkbox',
          displayCss: {
            "width": "80px"
          },
          events: {
            change: () => this.updateResult()
          }
        },
        {
          name: "create",
          display: "Create",
          type: 'checkbox',
          displayCss: {
            "width": "80px"
          },
          events: {
            change: () => this.updateResult()
          }
        },
        {
          name: "delete",
          display: "Delete",
          type: 'checkbox',
          displayCss: {
            "width": "80px"
          },
          events: {
            change: () => this.updateResult()
          }
        }
      ],
      hideButtons: {
        append: true,
        removeLast: true,
        insert: true,
        moveUp: true,
        moveDown: true,
        remove: true
      },
      hideRowNumColumn: true,
      initData: (initialItems || []).length ? initialItems : undefined,
    });

    this.updateResult();
  }

  updateResult() {
    if (this.grid) {
      const values = this.grid.getAllValue().map(item => {
        const accessKeys = [];
        if (item.show) accessKeys.push('show');
        if (item.create) accessKeys.push('create');
        if (item.edit) accessKeys.push('edit');
        if (item.delete) accessKeys.push('delete');

        return {
          id: item.id,
          access: accessKeys
        }
      });

      if (this.onUpdate) {
        this.onUpdate(values);
      }
    }
  }
}
