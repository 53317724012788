class AddressEditor {
  constructor (editor) {
    this.editor = editor;
    this.city = editor.find('input.address-city');
    this.result = editor.find('input.address-result');
    this.state = editor.find('input.address-state');
    this.streetAddress = editor.find('input.address-street');
    this.zip = editor.find('input.address-zip');

    this.parseAddress()

    editor.find('.address-control').on('change', () => this.updateAddress());
  }

  parseAddress() {
    const address = JSON.parse(this.result.val());
    this.state.val(address.state);
    this.city.val(address.city);
    this.zip.val(address.zip);
    this.streetAddress.val(address.street_address);
  }

  updateAddress () {
    const state = this.state.val();
    const city = this.city.val();
    const zip = this.zip.val();
    const streetAddress = this.streetAddress.val();

    let result = {
      fixed: true
    };

    let dirty = false;

    if (state.length > 0) {
      result.state = state;
      dirty = true;
    }

    if (city.length > 0) {
      result.city = city;
      dirty = true;
    }

    if (zip.length > 0) {
      result.zip = zip;
      dirty = true;
    }

    if (streetAddress.length > 0) {
      result.street_address = streetAddress;
      dirty = true;
    }

    if (dirty)
      result.full = [streetAddress, city, [state, zip].join(" ")].join(", ");
      this.result.val(JSON.stringify(result));
  }
}

$(document).on('rails_admin.dom_ready', () => $('.address-editor').each((_, e) => new AddressEditor($(e))));
