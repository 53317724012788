export default class {
  constructor({element, initialItems, onUpdate}) {
    this.onUpdate = onUpdate;

    this.grid = new AppendGrid({
      element,
      uiFramework: "bootstrap4",
      sectionClasses: {
        table: "table-float"
      },
      columns: [
        {
          name: 'name',
          display: 'Name',
          type: 'string',
          events: {
            change: () => this.updateResult()
          }
        },
        {
          name: 'value',
          display: 'Value',
          type: 'string',
          events: {
            change: () => this.updateResult()
          }
        }
      ],
      hideButtons: {
        insert: true,
      },
      hideRowNumColumn: true,
      initData: (initialItems || []).length ? initialItems : undefined,
      initRows: 1,

      afterRowAppended: () => {
        this.updateResult();
      },
      afterRowRemoved: () => {
        this.updateResult();
      },
      dataLoaded: () => {
        this.updateResult();
      }
    });

    this.updateResult();
  }

  updateResult() {
    this.onUpdate && this.grid && this.onUpdate(this.grid.getAllValue().filter(item => item.name !== ""))
  }
}
