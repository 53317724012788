$(document).on('rails_admin.dom_ready', () => {
  $('.copy-text').each((_, e) => {
    const orderSummary = $(e);

    orderSummary.find('button.btn-copy-text').on('click', () => {
      const summary = orderSummary.find('textarea');
      summary.select();
      document.execCommand('copy');
    });
  });
});
