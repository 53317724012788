export default class {
  constructor({element, initialItems, types, decorates, onUpdate}) {
    this.onUpdate = onUpdate;

    this.grid = new AppendGrid({
      element,
      uiFramework: "bootstrap4",
      sectionClasses: {
        table: "table-float"
      },
      columns: [
        {
          name: 'product_type_price_id',
          type: 'hidden'
        },
        {
          name: 'product_decorate_price_id',
          type: 'hidden'
        },
        {
          name: 'product_type_id',
          display: 'Type',
          type: 'select',
          value: "",
          ctrlClass: 'price_name',
          ctrlOptions: element => {
            Object.entries(types.reduce((result, item) => {
              if (!result[item.productName]) {
                result[item.productName] = [];
              }
              result[item.productName].push(item)
              return result
            }, {})).forEach(([productName, items]) => {
              Object.entries(items.reduce((result, item) => {
                if (!result[item.group]) {
                  result[item.group] = [];
                }
                result[item.group].push(item)
                return result
              }, {})).forEach(([groupName, items]) => {
                const group = document.createElement("optgroup");
                group.label = `${productName} (${groupName})`;
                element.appendChild(group);

                items.forEach(item => {
                  group.appendChild(new Option(item.name, item.id));
                })
              });
            })
          },
          ctrlAdded: (ctrl, tbCell, uniqueIndex) =>
            setTimeout(() => {
              $(ctrl).select2({
                placeholder: 'Select Type',
                theme: 'bootstrap',
                dropdownCssClass: 'select2-large'
              }).on('change', () => this.updateResult());
            }, 200)
        },
        {
          name: 'product_decorate_id',
          display: 'Color (Optional)',
          type: 'select',
          value: "",
          ctrlClass: 'price_name',
          ctrlOptions: element => {
            decorates.forEach(item => {
              element.appendChild(new Option(item.name, item.id));
            })
          },
          ctrlAdded: (ctrl, tbCell, uniqueIndex) =>
            setTimeout(() => {
              $(ctrl).select2({
                placeholder: 'Select Color',
                theme: 'bootstrap',
                allowClear: true,
                dropdownCssClass: 'select2-large'
              }).on('change', () => this.updateResult());
            }, 200)
        },
        {
          name: 'quantity',
          display: 'Quantity',
          type: 'number',
          disabled: true,
          ctrlAttr: {
            min: 0,
            step: 0.01
          },
          ctrlCss: {
            "max-width": "80px"
          },
          events: {
            change: () => this.updateResult()
          }
        },
        {
          name: 'product_type_price_value',
          display: 'Type Price',
          type: 'number',
          ctrlAttr: {
            min: 0,
            step: 0.01
          },
          ctrlCss: {
            "max-width": "80px"
          },
          events: {
            change: () => this.updateResult()
          }
        },
        {
          name: 'product_decorate_price_value',
          display: 'Color Price',
          type: 'number',
          ctrlAttr: {
            min: 0,
            step: 0.01
          },
          ctrlCss: {
            "max-width": "80px"
          },
          events: {
            change: () => this.updateResult()
          }
        },
      ],
      hideButtons: {
        insert: true,
        moveUp: true,
        moveDown: true
      },
      hideRowNumColumn: true,
      initData: (initialItems || []).length ? initialItems : undefined,
      initRows: 1,

      afterRowAppended: () => {
        this.updateResult();
      },
      afterRowRemoved: () => {
        this.updateResult();
      },
      dataLoaded: () => {
        this.updateResult();
      }
    });

    for (let i = 0; i < this.grid.getRowCount(); i++) {
      if (this.grid.getCtrlValue('product_type_price_id', i)) {
        $(this.grid.getCellCtrl('product_type_id', i)).prop('disabled', true);
        $(this.grid.getCellCtrl('product_decorate_id', i)).prop('disabled', true);
        $(this.grid.getCellCtrl('product_type_price_value', i)).prop('disabled', true);
        $(this.grid.getCellCtrl('product_decorate_price_value', i)).prop('disabled', true);
      }
    }

    this.updateResult();

  }

  updateResult() {
    this.onUpdate && this.grid && this.onUpdate(this.grid.getAllValue().filter(item => item.product_type_id != null))
  }
}
