$(document).on('rails_admin.dom_ready', () => {
  $('.product-type-description').each((_, e) => {
    const editor = new JSONEditor(e, {
      form_name_root: 'descriptionForm',
      schema: {
        type: 'array',
        title: 'Description',
        items: {
          title: 'Section',
          type: 'string',
          format: 'textarea',
          options: {
            input_height: '150px'
          }
        }
      }
    });

    editor.setValue(JSON.parse($('.product-type-description-result').val()));

    editor.on('change', () => {
      $('.product-type-description-result').val(JSON.stringify(editor.getValue()));
    });
  });
});
