$(document).on('rails_admin.dom_ready', () => {
  $('.image-link').magnificPopup({
    type: 'image'
  });

  // $('.image-gallery').each((_, e) => {
  //   $(e).magnificPopup({
  //     delegate: 'a',
  //     type: 'image',
  //     gallery: {
  //       enabled: true,
  //       navigateByImgClick: true,
  //       preload: [0, 1]
  //     }
  //   });
  // });
  //
  // $('.image-gallery-link').magnificPopup({
  //   type: 'image',
  //   gallery: {
  //     enabled: true
  //   }
  // });
});
