class PartnerPayout {
    constructor(form) {
        this.amount = form.find("#amount");
        this.transactionFee = form.find("#transaction_fee");
        this.payout = form.find("#payout");

        this.amount.on("input", () => this.onAmountChange());

        this.onAmountChange();
    }

    onAmountChange() {
        const amount = this.amount.val();
        const fee = (amount * 0.049);
        const payout = amount - fee;

        this.transactionFee.text(fee.toFixed(2));
        this.payout.text(payout.toFixed(2));
    }
}

$(document).on('rails_admin.dom_ready', () => {
    $(".form-partner-payout").each((_, e) => new PartnerPayout($(e)));
});
