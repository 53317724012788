class WorkingHoursEditor {
  constructor (editor) {
    this.editor = editor;
    this.weekdays = editor.find('input.wh-days');
    this.times = editor.find('.wh-times');
    this.startHours = editor.find('.wh-start-hours');
    this.startMinutes = editor.find('.wh-start-minutes');
    this.endHours = editor.find('.wh-end-hours');
    this.endMinutes = editor.find('.wh-end-minutes');

    this.useDate = editor.find('.wh-use-date');
    this.date = editor.find('.wh-date');

    this.result = editor.find('.wh-result');
    this.error = editor.find('.wh-error');

    this.hasErrors = false;

    editor.find('.wh-control').on('change', () => this.updateRrule());

    this.useDate.on('change', () => this.toggleDate());

    this.parseRrule();
  }

  addError(element, message) {
    element.addClass('has-error');
    this.error.html(this.error.html() + '<br>' + message);
    this.hasErrors = true;
  }

  clearErrors() {
    this.editor.find('.has-error').removeClass('has-error');
    this.error.html('');
    this.hasErrors = false;
  }

  parseRrule () {
    const ruleStr = this.result.val();
    if (ruleStr === undefined) {
      return;
    }

    const rule = rrule.RRule.parseString(ruleStr);

    if (rule.byweekday !== undefined) {
      const weekdays = rule.byweekday.map(item => item.weekday.toString());
      this.weekdays.each((_, e) => {
        const check = $(e);
        if (weekdays.indexOf(check.val()) > -1) {
          check.prop('checked', true);
        }
      });
    }

    if (rule.byminute !== undefined && rule.byminute.length > 0) {
      this.startHours.val(rule.byhour * 60);
      this.startMinutes.val(rule.byminute[0]);
      
      const totalMinutes = rule.byminute[rule.byminute.length - 1];
      const reminderMinutes = totalMinutes % 60;
      const totalHours = (totalMinutes - reminderMinutes) / 60;
      const endHour = rule.byhour + totalHours;

      if (totalHours == 24) {
        this.endHours.val(rule.byhour * 60);
        this.endMinutes.val(rule.byminute[0]);
      } else {
        if (endHour < 24) {
          this.endHours.val(endHour * 60);
        } else {
          this.endHours.val((endHour - 24) * 60);
        }
        this.endMinutes.val(reminderMinutes);
      }
    }
  }

  toggleDate() {
    const useDate = this.useDate.prop('checked');
    this.weekdays.prop('disabled', useDate);
    this.date.prop('disabled', !useDate);
  }

  updateRrule () {
    this.clearErrors();

    const weekdays = [];

    this.weekdays.filter(':checked').each((_, e) => weekdays.push($(e).val()));

    const startValue = parseInt(this.startHours.val(), 10) + parseInt(this.startMinutes.val(), 10);
    const endValue = parseInt(this.endHours.val(), 10) + parseInt(this.endMinutes.val(), 10);

    const useDate = this.useDate.prop('checked');
    const date = new Date(this.date.val());

    const hours = Math.floor(startValue / 60);

    const minutesStart = startValue - hours * 60;
    const minutesEnd = endValue - hours * 60;

    const minutes = [];

    if (useDate && isNaN(date)) {
      this.addError(this.editor.find('.wh-date-container'), 'Invalid date');
    }

    if (this.hasErrors) {
      this.result.val('');
      return;
    }
  
    let value = minutesStart;
    if (startValue === endValue) {
      while (value <= 1440) {
        minutes.push(value);
        value += 15;
      }
    } else if (minutesEnd >= 0) {
      while (value <= minutesEnd) {
        minutes.push(value);
        value += 15;
      }
    } else {
      while (minutesEnd - value >= -24*60) {
        minutes.push(value);
        value += 15;
      }
    }

    let options = {};
    if (useDate) {

      options = {
        freq: rrule.RRule.WEEKLY,
        bymonth: date.getMonth() + 1,
        bymonthday: date.getDate(),
        byhour: [hours],
        byminute: minutes
      };
    } else {
      options = {
        freq: rrule.RRule.DAILY,
        byweekday: weekdays,
        byhour: [hours],
        byminute: minutes,
        interval: 1
      };
    }

    const rule = new rrule.RRule(options);
    this.result.val(rule.toString().replace(/^RRULE:/, ''));
  }
}

$(document).on('rails_admin.dom_ready', () => $('.working-hours').each((_, e) => new WorkingHoursEditor($(e))));
