import SimpleMDE from 'simplemde';

export default class {
  constructor({element, value}) {
    const id = `${element}_textarea`
    const component = document.createElement("textarea");
    component.id = id;
    document.getElementById(element).appendChild(component)

    this.editor = new SimpleMDE({
      element: document.getElementById(id),
      spellChecker: false,
      status: false,
      toolbar: false,
    });

    this.editor.value(value || "")
    this.editor.togglePreview();
  }
}
