$(document).on('rails_admin.dom_ready', () => {
  JSONEditor.defaults.options.disable_collapse = true;
  JSONEditor.defaults.options.disable_edit_json = true;
  JSONEditor.defaults.options.disable_properties = true;
  JSONEditor.defaults.options.iconlib = 'fontawesome4';
  JSONEditor.defaults.options.remove_button_labels = true;
  JSONEditor.defaults.options.no_additional_properties = true;
  JSONEditor.defaults.options.theme = 'bootstrap4';
  JSONEditor.defaults.options.form_name_root = 'jsonform';
  JSONEditor.defaults.options.prompt_before_delete = false;
});
