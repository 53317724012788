export default class {
  constructor({element, items}) {
    this.grid = new AppendGrid({
      element,
      uiFramework: "bootstrap4",
      sectionClasses: {
        table: "table-float"
      },
      columns: [
        {
          name: 'name',
          display: 'Name',
          type: 'string',
          ctrlAttr: {
            disabled: true
          },
        },
        {
          name: 'value',
          display: 'Value',
          type: 'string',
          ctrlAttr: {
            disabled: true
          },
        }
      ],
      hideButtons: {
        insert: true,
        moveUp: true,
        moveDown: true,
        remove: true,
        append: true,
        removeLast: true
      },
      hideRowNumColumn: true,
      initData: (items || []).length ? items : undefined,
      initRows: 0,
    });
  }
}
