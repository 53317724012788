import './common/address';
import './common/check_list_box';
import './common/copy_text';
import './common/image_lightbox';
import './common/working_hours';

import './common/jsoneditor/jsoneditor_defaults';
import './common/jsoneditor/product_type_description';
import './common/jsoneditor/product_type_images';
import './common/jsoneditor/product_type_options';

import * as common from './common'
import * as contractor from './contractor'
import * as order from './order'
import * as product_type from './product_type'
import * as partner from './partner'

import * as plants from './plants'
import * as role from './role'

window.classes = {
  ...(window.classes || {}),
  common,
  contractor,
  order,
  product_type,
  partner,
  plants,
  role
}
