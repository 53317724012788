$(document).on('rails_admin.dom_ready', () => {
  $('.product-type-images').each((_, e) => {
    const editor = new JSONEditor(e, {
      form_name_root: 'imagesForm',
      disable_array_add: true,
      compact: true,
      schema: {
        type: 'array',
        title: 'Description Images',
        items: {
          title: 'Image',
          type: 'string',
          links: [
            {
              href: '{{self}}',
              mediaType: 'image/png',
              class: 'image-preview'
            }
          ]
        }
      }
    });

    editor.setValue(JSON.parse($('.product-type-images-result').val()));

    editor.on('change', () => {
      $('.product-type-images-result').val(JSON.stringify(editor.getValue()));
    });
  });
});
