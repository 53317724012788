$(document).on('rails_admin.dom_ready', () => {
  $('.product-type-options').each((_, e) => {
    const editor = new JSONEditor(e, {
      form_name_root: 'optionsForm',
      schema: {
        type: 'array',
        title: 'Options',
        items: {
          title: 'Option',
          type: 'object',
          properties: {
            name: {
              type: 'string',
              required: true,
              title: 'Name'
            },
            value: {
              type: 'string',
              required: true,
              title: 'Value'
            }
          }
        },
        format: 'table'
      }
    });

    editor.setValue(JSON.parse($('.product-type-options-result').val()));

    editor.on('change', () => {
      $('.product-type-options-result').val(JSON.stringify(editor.getValue()));
    });
  });
});
