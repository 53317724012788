export default class {
  constructor({element, initialItems, options, onUpdate}) {
    this.onUpdate = onUpdate;

    this.grid = new AppendGrid({
      element,
      uiFramework: "bootstrap4",
      sectionClasses: {
        table: "table-float"
      },
      columns: [
        {
          name: 'price_id',
          display: 'Price ID',
          type: 'hidden'
        },
        {
          name: 'option_id',
          display: 'Option',
          type: 'select',
          ctrlClass: 'price_name',
          value: "",
          ctrlOptions: element => {
            Object.entries(options.reduce((result, item) => {
              if (!result[item.group]) {
                result[item.group] = [];
              }
              result[item.group].push(item)
              return result
            }, {})).forEach(([type, items]) => {
              const group = document.createElement("optgroup");
              group.label = type;
              element.appendChild(group);

              items.forEach(item => {
                group.appendChild(new Option(item.name, item.id));
              })
            })
          },
          ctrlAdded: (ctrl, tbCell, uniqueIndex) => {
            setTimeout(() => {
              $(ctrl).select2({
                placeholder: 'Select option',
                theme: 'bootstrap',
                dropdownCssClass: 'select2-large'
              }).on('change', () => this.updateResult());
            }, 200)
          }
        },
        {
          name: 'price_value',
          display: 'Price',
          type: 'number',
          ctrlAttr: {
            min: 0,
            step: 0.01
          },
          ctrlCss: {
            "max-width": "80px"
          },
          events: {
            change: () => this.updateResult()
          }
        },
        {
          name: 'price_content',
          display: 'Content',
          type: 'select',
          ctrlOptions: ['unit', 'load', 'onetime'],
          events: {
            change: () => this.updateResult()
          }
        }
      ],
      hideButtons: {
        insert: true,
        moveUp: true,
        moveDown: true
      },
      hideRowNumColumn: true,
      initData: (initialItems || []).length ? initialItems : undefined,
      initRows: 1,

      afterRowAppended: () => {
        this.updateResult();
      },
      afterRowRemoved: () => {
        this.updateResult();
      },
      dataLoaded: () => {
        this.updateResult();
      }
    });

    for (let i = 0; i < this.grid.getRowCount(); i++) {
      if (this.grid.getCtrlValue('price_id', i)) {
        $(this.grid.getCellCtrl('option_id', i)).prop('disabled', true);
        $(this.grid.getCellCtrl('price_value', i)).prop('disabled', true);
        $(this.grid.getCellCtrl('price_content', i)).prop('disabled', true);
      }
    }

    this.updateResult();
  }

  updateResult() {
    this.onUpdate && this.grid && this.onUpdate(this.grid.getAllValue().filter(item => item.option_id !== ""))
  }
}
