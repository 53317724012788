import SimpleMDE from 'simplemde';

export default class {
  constructor({element, initialValue, onUpdate}) {
    this.onUpdate = onUpdate;

    const id = `${element}_textarea`
    const component = document.createElement("textarea");
    component.id = id;
    document.getElementById(element).appendChild(component)

    this.editor = new SimpleMDE({
      element: document.getElementById(id),
      spellChecker: false,
      status: false,
      toolbar: ["bold", "italic", "|", "heading-1", "heading-2", "heading-3", "|", "unordered-list", "ordered-list", "|", "preview"]
    });

    this.editor.value(initialValue)

    this.editor.codemirror.on("change", () => {
      onUpdate(this.editor.value());
    });
  }
}
