export default class {

// - content_for :head do
//     = javascript_include_tag "https://maps.googleapis.com/maps/api/js?key=#{ENV.fetch("GOOGLE_API_KEY")} async defer"

  // const myApiKey = `12345`;
  // const lat = -34.397;
  // const lng = 150.644;
  // const zoom = 8;
  //
  // const parentElement = document.getElementById(`map`); // a <div>
  // const script = document.createElement(`script`);
  // script.src = `https://maps.googleapis.com/maps/api/js?key=${myApiKey}`;
  // script.async = true;
  // script.defer = true;
  // script.onload = function () {
  //   new google.maps.Map(parentElement, {
  //     center: {lat, lng},
  //     zoom
  //   });
  // };
  // parentElement.insertBefore(script, null);

  constructor({element, googleApiKey}) {
    this.element = document.getElementById(element);

    this.load({
      googleApiKey
    }).then(value => {
      this.start();
    })
  }

  async load({googleApiKey}) {
    if(!(typeof google === 'object' && typeof google.maps === 'object')) {
      await new Promise(resolve => {
        const script = document.createElement(`script`);
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}`;
        script.async = true;
        script.defer = true;
        script.onload = resolve;

        this.element.insertBefore(script, null);
      })
    }
  }

  start() {
    const myOptions = {
      zoom: 15,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false
    };

    this.map = new google.maps.Map(this.element, myOptions);
  }


}
