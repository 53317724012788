export default class {

  onUpdate;
  sortOrderHelper;
  filter;
  visibleItems;
  hiddenItems;
  sortableIds;

  constructor({element, items, columns, withPriceContent, onUpdate}) {
    this.onUpdate = onUpdate;

    this.sortableIds = columns.map(items => items.id)

    this.sortOrderHelper = columns.reduce((result, item) => {
      return {
        ...result,
        [item.id]: item.items.reduce((result, item) => ({...result, [item.id]: item.sortOrder || item.name}), {})
      }
    }, {})

    this.filter = {}

    this.visibleItems = items || [];
    this.hiddenItems = [];

    this.prepareItems();

    this.grid = new AppendGrid({
      element,
      uiFramework: "bootstrap4",
      sectionClasses: {
        table: "table-float"
      },
      columns: [
        {
          name: 'price_id',
          display: 'Price ID',
          type: 'hidden'
        },
        ...(columns.map(column => this.prepareColumn({
          id: column.id,
          label: column.label,
          filterable: column.filterable,
          filterItems: column.items,
          cellItems: column.items,
          required: column.required,
          groupBy: column.groupBy
        }))),
        this.prepareColumn({
          id: "price_currency",
          label: "Currency",
          type: 'select',
          value: 'cad',
          ctrlOptions: 'cad:CAD;usd:USD;aud:AUD;gbp:GBP',
          events: {
            change: () => this.updateResult()
          }
        }),
        this.prepareColumn({
          id: "price_value",
          label: "Price",
          type: 'number',
          required: true,
          ctrlAttr: {
            min: 0,
            step: 0.01
          }
        }),
        this.prepareColumn({
          id: "price_content",
          label: "Content",
          type: withPriceContent ? 'select' : 'hidden',
          value: 'unit',
          ctrlOptions: ['unit', 'load', 'onetime'],
          events: {
            change: () => this.updateResult()
          }
        })
      ],
      hideButtons: {
        insert: true,
        moveUp: true,
        moveDown: true
      },
      hideRowNumColumn: true,
      initData: this.visibleItems,

      afterRowAppended: () => {
        this.updateResult();
      },
      afterRowRemoved: () => {
        this.updateResult();
      },
      dataLoaded: () => {
        this.updateResult();
      }
    });

    this.updateResult();
  }

  prepareItems() {
    const all = [
      ...this.visibleItems,
      ...this.hiddenItems
    ].filter(value => value.price_value != null && value.price_value !== "")

    const filterKeys = Object.keys(this.filter);

    this.visibleItems = all.filter(value => {
      return filterKeys.length === 0 || filterKeys.every(filterKey => {
        const filterValue = this.filter[filterKey];

        if (filterValue === "") return true;
        if (filterValue === "null") {
          return value[filterKey] == null || value[filterKey] === "" || value[filterKey] === "null"
        }

        return value[filterKey] === filterValue
      })
    })

    this.hiddenItems = all.filter(value => !this.visibleItems.includes(value))

    this.visibleItems = this.visibleItems.sort((a, b) => {
      for (let field of this.sortableIds) {
        const value1 = a[field] === "" ? null : a[field];
        const value2 = b[field] === "" ? null : b[field];

        if (value1 == null && value2 != null) {
          return -1;
        }

        if (value1 != null && value2 == null) {
          return 1;
        }

        if (value1 !== value2) {
          return this.sortOrderHelper[field][value1] < this.sortOrderHelper[field][value2] ? -1 : 1
        }
      }

      return 0;
    })

    if (this.visibleItems.length === 0) {
      this.visibleItems = [{price_currency: "cad", price_content: "unit"}]
    }
  }

  applyFilter(value) {
    this.filter = {
      ...this.filter,
      ...value
    }

    this.prepareItems();

    this.grid.load(this.visibleItems);
  }

  prepareColumn({id, label, filterable, filterItems, cellItems, required, groupBy, ctrlAttr, ...props}) {
    return {
      name: id,
      display: filterable ? holder => this.prepareFilteredColumn(holder, label, id, filterItems, groupBy, required) : label,
      displayClass: filterable ? "filterable-column-title" : "column-title",
      type: cellItems ? 'select' : undefined,
      value: "",
      ctrlOptions: cellItems ? element => {
        this.prepareSelectOptions(element, cellItems, groupBy, required)
      } : undefined,
      ctrlAdded: cellItems ? (ctrl, tbCell, uniqueIndex) => {
        setTimeout(() => {
          $(ctrl).select2({
            theme: 'bootstrap',
            width: 'resolve',
            dropdownCssClass: `select2-large ${cellItems.length > 13 ? '' : 'select2-no-search'}`
          }).on('change', () => {
            this.updateResult()
          });
        }, 200)
      } : undefined,
      ctrlAttr: {
        ...(required ? { required: "required" } : {}),
        ...(ctrlAttr || {})
      },
      events: cellItems ? undefined : {
        change: () => this.updateResult()
      },
      ...props
    }
  }

  prepareSelectOptions(element, items, groupBy, required) {

    element.appendChild(new Option("", ""));

    if (!required) {
      element.appendChild(new Option("N/A", "null"));
    }

    if (groupBy == null || groupBy === "") {
      items.forEach(item => {
        element.appendChild(new Option(item.name, item.id));
      })
    } else {
      Object.entries(items.reduce((result, item) => {
        const groupName = item[groupBy];
        if (!result[groupName]) {
          result[groupName] = [];
        }
        result[groupName].push(item)
        return result
      }, {})).forEach(([groupName, items]) => {
        const group = document.createElement("optgroup");
        group.label = groupName;
        element.appendChild(group);

        items.forEach(item => {
          group.appendChild(new Option(item.name, item.id));
        })
      })
    }
  }

  prepareFilteredColumn(holder, label, column, items, groupBy, required) {
    const id = `${column}-filter`

    $("<select></select>")
      .attr({ id: id, class: "filtered-column-select" })
      .appendTo(holder)

    $(holder).append("<hr>")
    $(holder).append(label)

    this.prepareSelectOptions(document.getElementById(id), items, groupBy, required);

    $(`#${id}`).select2({
      placeholder: `Filter by ${label}`,
      theme: 'bootstrap',
      allowClear: true,
      width: 'resolve',
      dropdownCssClass: `select2-large ${items.length > 13 ? '' : 'select2-no-search'}`
    }).on('change', ({target}) => {
      this.applyFilter({
        [column]: target.value
      })
    });
  }

  updateResult() {
    if (this.grid) {
      this.visibleItems = this.grid.getAllValue();
    }

    if (this.onUpdate) {
      this.onUpdate([
        ...this.visibleItems,
        ...this.hiddenItems,
      ].filter(item => item.price_value !== ""))
    }
  }
}
