class OrderAttachPayment {
    constructor(form) {
        const elements = stripe.elements();

        this.card = elements.create("card", { hidePostalCode: true });
        this.card.mount("#card-element");

        this.button = form.find("#card-button");
        this.displayError = form.find("#card-errors");
        this.spinner = form.find(".fa-spinner");
        this.submitting = false;

        this.card.on("change", (e) => this.onCardChange(e));
        form.on("submit", (e) => this.onSubmit(form, e));
    }

    onCardChange(event) {
        if (event.error) {
            this.displayError.text(event.error.message);
        } else {
            this.displayError.text("");
        }
    }

    onSubmit(form, event) {
        event.preventDefault();

        if (this.submitting) {
            return;
        }

        this.submitting = true;

        let clientSecret = form.data("secret");

        this.spinner.show();
        this.button.addClass("disabled");

        stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: this.card
            }
        }).then((result) => {
            this.button.removeClass("disabled");
            this.spinner.hide();
            this.submitting = false;

            console.log(result);

            if (result.error) {
                this.displayError.text(result.error.message);
            } else {
                if (result.paymentIntent.status === "requires_capture") {
                    $.post(form.action, {
                        intent_id: result.paymentIntent.id,
                        payment_method_id: result.paymentIntent.payment_method,
                        payment_success: "1"
                    })
                }
            }
        });
    }
}

$(document).on('rails_admin.dom_ready', () => {
    $("#form-payment").each((_, e) => new OrderAttachPayment($(e)));
});
